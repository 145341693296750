<template>
  <b-modal
    v-model="isOpen"
    size="lg"
    title="Usuários Notificados"
    ok-only
    hide-header-close
    ok-title="Fechar"
    @ok="close"
  >
    <b-card>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(addNumber)"
          @reset.prevent="close"
        >

          <b-row class="align-items-center">
            <b-col
              md="10"
              sm="10"
            >
              <i2-form-multiple-select
                v-model="model.users"
                name="users"
                label="Usuários"
                rules="required"
                :options="usersAvailable"
              />
            </b-col>
            <b-col>
              <b-button
                variant="primary"
                @click="addUsers"
              >
                <FeatherIcon icon="PlusIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <Grid
        :fields="fields"
        :items="items"
        :show-search="false"
        :show-pagination="false"
        edit-button-label="edit"
        new-button-label="new"
      >
        <template #cell(name)="data">
          {{ data.item.user.name }}<br>
          <b>E-mail:</b> {{ data.item.user.email }}<br>
          <b>WhatsApp:</b> {{ data.item.user.phone_number }}
        </template>
        <template #cell(action)="data">
          <i2-icon-button
            variant="outline-danger"
            icon="TrashIcon"
            @click="deleteUserNotification(data.item)"
          />
        </template>
      </Grid>
    </b-card>
  </b-modal>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import I2FormMultipleSelect from '@/components/i2/forms/elements/I2FormMultipleSelect.vue'

export default {
  name: 'NotificationUsersModal',
  components: {
    I2FormMultipleSelect,
    ValidationObserver,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      model: {
        subject: 'monitoring',
        users: [],
      },
      usersAvailable: [],
      items: [],
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-80',
        },
        {
          key: 'action',
          label: 'Ação',
          class: 'w-10 text-center',
        },
      ],
    }
  },
  watch: {
    open(value) {
      this.isOpen = value
      if (value) {
        this.init()
      }
    },
  },
  methods: {
    init() {
      this.resetModel()
      this.getUsers()
      this.getAvailableUsers()
    },
    resetModel() {
      this.model = {
        subject: 'monitoring',
        users: [],
      }
    },
    async getAvailableUsers() {
      const response = await this.$http.get('user')
      this.usersAvailable = []
      // eslint-disable-next-line array-callback-return
      response.map(user => {
        const userSaved = this.items.find(item => item.user.uuid === user.uuid)
        if (!userSaved) {
          this.usersAvailable.push({
            text: user.name,
            uuid: user.uuid,
            value: user.uuid,
          })
        }
      })
    },
    close() {
      this.$refs.refFormObserver.reset()
      this.$emit('close')
    },
    async addUsers() {
      const validate = await this.$refs.refFormObserver.validate()
      if (!validate) {
        return
      }

      await this.$http.post('notification/users', this.model)
      await this.$refs.refFormObserver.reset()
      this.init()
    },
    async getUsers() {
      this.items = await this.$http.get(`notification/users?subject=${this.model.subject}`)
    },
    async deleteUserNotification(item) {
      this.$bvModal
        .msgBoxConfirm('Ao excluir esse usuário, ele não será mais notificado.', {
          title: 'Confirma a exclusão?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        }).then(async response => {
          if (!response) {
            return
          }
          await this.$http.delete(`notification/users/${item.uuid}`)
          this.$notify.success('Usuário não será mais notificado.')
          this.init()
        })
    },
  },
}
</script>

<style scoped>

</style>
