export default [
  {
    value: 'super_admin',
    text: 'Super Admin',
  },
  {
    value: 'user',
    text: 'Usuário sistema',
  },
]
