<template>

  <!-- BODY -->
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <!-- Form -->
    <b-form
      class="p-2"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="onCancel"
    >
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <i2-form-input
            v-model="model.name"
            rules="required"
            label="Nome"
            placeholder="informe o nome do usuário"
            name="name"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <i2-form-input
            v-model="model.email"
            rules="required|email"
            label="E-mail (login)"
            placeholder="Login do usuário (email)"
            name="email"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <i2-form-input
            v-model="model.phone_number"
            mask="(##) # ####-####"
            label="Telefone (WhatsApp)"
            placeholder="51 988887777"
            name="phone_number"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <i2-form-vue-select
            v-model="model.type"
            name="type"
            rules="required"
            label="Tipo do usuário"
            :options="usersTypes"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <i2-form-password
            v-model="model.password"
            label="Senha"
            name="password"
            :rules="passwordValidator"
          />
        </b-col>
      </b-row>
      <i2-form-password
        v-model="model.confirm_password"
        label="Informe novamente a Senha"
        name="confirme a senha"
        :rules="confirmPasswordValidator"
      />
      <b-row>
        <b-col
          sm="12"
          md="12"
          class="text-right"
        >
          <!-- Form Actions -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mr-2"
          >

            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Salvar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            type="button"
            @click="onCancel"
          >

            <font-awesome-icon
              prefix="far"
              :icon="['far', 'times-circle']"
              class="mr-50"
            />
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import I2FormPassword from '@/components/i2/forms/elements/I2FormPassword.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userType from '@/variables/userType'

export default {
  components: {
    I2FormInput,
    I2FormPassword,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      active: false,
      usersTypes: userType,
      companiesList: [],
      unitiesList: [],
      passwordValidator: 'password|min:6',
      lastCompany: '',
      confirmPasswordValidator: '',
      model: {
        name: '',
        uuid: null,
        email: '',
        type: {
          value: null,
          text: 'selecione',
        },
        password: '',
        confirm_password: '',
        phone_number: '',
      },
      originalData: {},
      blankUser: {
        name: '',
        uuid: '',
        email: '',
        phone_number: '',
        type: {
          value: null,
          text: 'selecione',
        },
        password: '',
        company: {
          value: null,
          text: 'selecione',
        },
        unities: [],
        confirm_password: '',
      },
    }
  },
  watch: {
    isAddNewUserSidebarActive(data) {
      this.active = data
    },
    user(data) {
      if (data.uuid === undefined) {
        this.model = { ...this.blankUser }
        this.passwordValidator = 'required|password|min:6'
        this.confirmPasswordValidator = 'required|confirmed:Senha|password'
        this.$refs.refFormObserver.reset()
        return
      }
      this.model = { ...data }

      this.model.type = this.usersTypes.find(type => type.value === data.type)
      if (data.uuid !== undefined && data.uuid !== '') {
        this.passwordValidator = 'password|min:6'
        this.confirmPasswordValidator = 'confirmed:Senha'
      }
      if (!data.uuid) {
        this.model = { ...this.blankUser }
        this.passwordValidator = 'required|password|min:6'
        this.confirmPasswordValidator = 'required|confirmed:Senha'
        this.$refs.refFormObserver.reset()
      }
    },
    // eslint-disable-next-line func-names
  },
  async mounted() {
    this.init()
  },
  methods: {
    init() {
      if (!this.user.uuid) {
        this.model = { ...this.blankUser }
        this.passwordValidator = 'required|password|min:6'
        this.confirmPasswordValidator = 'required|confirmed:Senha|min:6'
        this.$refs.refFormObserver.reset()
      }
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = { ...this.model }
          data.role = this.model.type.value
          if (this.model.uuid) {
            response = await this.$http.put(`user/${this.model.uuid}`, data)
          } else {
            response = await this.$http.post('user', data)
          }
          if (response.error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            return
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Usuário salvo com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$emit('onUserSave', response)
          this.model = { ...this.blankUser }
          this.$refs.refFormObserver.reset()
        }
      })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.model = { ...this.blankUser }
      this.$refs.refFormObserver.reset()
    },
  },
}
</script>
