<template>
  <div>
    <page-header
      name="Usuários do Sistema"
    >
      <b-button
        variant="primary"
        @click="newUser"
      >
        <FeatherIcon icon="PlusIcon" />
        <span class="text-nowrap">Adicionar</span>
      </b-button>

      <b-button
        class="ml-1"
        variant="outline-primary"
        @click="notificationUser"
      >
        <FeatherIcon icon="AlertTriangleIcon" />
        <span class="text-nowrap">Notificados</span>
      </b-button>
    </page-header>
    <b-card-code>
      <b-row>
        <b-col class="text-right" />
      </b-row>

      <i2-side-bar
        :is-active.sync="isActivated"
        window-title="Usuários"
      >
        <user-new
          :user="currentUser"
          @onUserSave="onUserSave()"
          @onCancel="onCancelEdit()"
        />
      </i2-side-bar>

      <Grid
        :fields="fields"
        :items="items"
        edit-button-label="edit"
        new-button-label="new"
      >
        <template #cell(type)="data">
          {{ userType.find(type => type.value === data.item.type).text }}
        </template>
        <template #cell(client)="data">
          {{ data.item.role === 'user' ? data.item.client.name : '' }}
        </template>
        <template
          #cell(action)="data"
          style="width: 10%"
        >
          <div class="px-4 text-center">
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="setCurrentUser(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteUser(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Excluir</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </Grid>
    </b-card-code>

    <notification-users-modal :open="isOpenModalNotification" @close="closeNotificationModal" />
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import UserNew from '@/views/users/UserNew.vue'
import Grid from '@/components/i2/grid/Grid.vue'
import I2SideBar from '@/components/i2/container/I2SideBar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userType from '@/variables/userType.js'
import PageHeader from '@/components/i2/layout/PageHeader.vue'
import NotificationUsersModal from '@/views/users/NotificationUsersModal.vue'

export default {
  components: {
    NotificationUsersModal,
    PageHeader,
    I2SideBar,
    Grid,
    BCardCode,
    UserNew,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-2',
        },
        {
          key: 'email',
          label: 'e-mail',
          class: 'w-2',
        },
        {
          key: 'client',
          label: 'Cliente',
          class: 'w-2',
        },
        {
          key: 'type',
          label: 'Perfil',
          class: 'w-4',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-5',
        },
      ],
      items: [],
      currentUser: {},
      isActivated: false,
      userType,
      isOpenModalNotification: false,
    }
  },
  watch: {
    isActivated(val) {
      if (!val) {
        this.currentUser = {}
      }
    },
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    async getUsers() {
      this.items = await this.$http.get('user')
    },
    setCurrentUser(user) {
      this.currentUser = { ...user }
      this.isActivated = true
    },
    newUser() {
      this.currentUser = { }
      this.isActivated = true
    },
    onUserSave() {
      this.currentUser = {}
      this.isActivated = false
      this.getUsers()
    },
    deleteUser(user) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme a exclusão do usuário.', {
          title: 'Confirma a exclusão?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            const response = await this.$http.delete(`user/${user.uuid}`)
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `O usuário ${user.name} foi removido com sucesso.`,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.getUsers()
            }
          }
          return true
        })
    },
    onCancelEdit() {
      this.isActivated = false
    },
    notificationUser() {
      this.isOpenModalNotification = true
    },
    closeNotificationModal() {
      this.isOpenModalNotification = false
    },
  },
}
</script>
<style>
th.w-1 {
  width: 50%;
}
th.w-2 {
  width: 20%;
}
th.w-3 {
  width: 10%;
}
th.w-10 {
  width: 20%;
  text-wrap: none;
}
</style>
